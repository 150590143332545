import { loadChildGalleries } from "./california-gallery";

export function bindViewProductOwnershipModalEvents(modal: HTMLDivElement) {
  const mobileOpenEditorButton = modal.querySelector<HTMLElement>(
    ".product-comment-action--mobile"
  );

  mobileOpenEditorButton?.addEventListener("click", () => {
    mobileOpenEditorButton.classList.add("hidden--mobile");
    const editor = modal.querySelector<HTMLElement>(".product-comment-editor");
    editor!.classList.remove("hidden--mobile");

    const submitButton = editor?.querySelector('button[type="submit"]');
    submitButton?.addEventListener("click", () => {
      editor!.classList.add("hidden--mobile");
      mobileOpenEditorButton.classList.remove("hidden--mobile");
    });
  });

  const productImageGallery = modal?.querySelector<HTMLDivElement>(
    ".product-image-gallery"
  );
  if (productImageGallery) {
    loadChildGalleries(productImageGallery);
  }
}
