import { bindProductFormEvents } from "./js/product-ownership-form";
import { bindViewProductOwnershipModalEvents } from "./js/view-product-ownership";
import { XF } from "./js/XF";

const leaderboardSection = document.querySelector(
  "#productOwnershipLeaderboard"
) as HTMLElement;

const leaderboardContainer = document.querySelector(
  "#leaderboardContainer"
) as HTMLDivElement;

if (!leaderboardContainer) {
  throw new Error("Could not find product ownership leaderboard");
}

async function fetchLeaderboard() {
  const { threadId } = leaderboardSection.dataset;
  const data = await $.get("/product-ownership/leaderboard", {
    threadId,
  });
  if (!data) {
    leaderboardSection.style.display = "none";
  } else {
    leaderboardContainer.innerHTML = data.html.content;
    XF.activate(leaderboardContainer);
    bindLeaderboardEvents();
  }
}

function bindLeaderboardEvents() {
  leaderboardSection
    .querySelector<HTMLButtonElement>(".js-add-product-ownership")
    ?.addEventListener("click", () => {
      const addFormModalSelector = ".add-product-modal.product-ownership-modal";
      waitForAddFormModalAndBindEvents(addFormModalSelector);
    });

  leaderboardContainer
    .querySelectorAll<HTMLDivElement>(".js-view-product-ownership")
    .forEach((viewLink) => {
      viewLink.addEventListener("click", () => {
        const { productOwnershipId } = (viewLink as HTMLDivElement).dataset;
        const modalId = `#viewProductOwnership${productOwnershipId}`;
        waitForViewModalAndBindEvents(modalId);
      });
    });
}

function waitForAddFormModalAndBindEvents(selector: string) {
  requestAnimationFrame(() => {
    const modal = document.querySelector<HTMLDivElement>(selector);
    if (!modal) {
      waitForAddFormModalAndBindEvents(selector);
    } else {
      bindProductFormEvents(modal);
    }
  });
}

function waitForViewModalAndBindEvents(selector: string) {
  requestAnimationFrame(() => {
    const modal = document.querySelector<HTMLDivElement>(selector);
    if (!modal) {
      waitForViewModalAndBindEvents(selector);
    } else {
      bindViewProductOwnershipModalEvents(modal);
    }
  });
}

const leaderboardObserver = new IntersectionObserver(async (entries) => {
  if (entries.some((i) => i.isIntersecting)) {
    leaderboardObserver.disconnect();
    try {
      await fetchLeaderboard();
    } catch (err) {
      leaderboardSection.style.display = "none";
    }
  }
});
leaderboardObserver.observe(leaderboardContainer);
